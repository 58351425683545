import React from 'react';
import { endpoint } from '../../App';

class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            password: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        fetch(`${endpoint}/campaignsGallery`, {
            method: 'POST',
            body: JSON.stringify({ password: this.state.password }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            if (res.status === 200) {
                const data = await res.json();
                delete data.images;
                localStorage.setItem('gallery_data', JSON.stringify(data));
                this.props.history.push('/');
            } else {
                return alert('Wrong password');
            }
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <div className="Login">
                <div className="logo">
                    <img src="/assets/image.png" alt="Logo" />
                </div>
                <form onSubmit={this.handleSubmit}>
                    <input
                        type="password"
                        name="password"
                        placeholder="Mot de passe"
                        autoComplete="off"
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                    <input type="submit" value="Go" />
                </form>
            </div>
        );
    }
}

export default Login;
