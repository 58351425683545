import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';

export const SimpleMarkerOnMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap defaultZoom={11} defaultCenter={props.position}>
            <Marker position={props.position} />
        </GoogleMap>
    ))
);
