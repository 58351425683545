import React from 'react';
import dateFormat from 'dateformat';
import { SimpleMarkerOnMap } from '../Ui/Map';
import { endpoint, cloud_endpoint } from '../../App';
import ReactTags from 'react-tag-autocomplete';
import JSZip from 'jszip';
import saveAs from 'file-saver';

const Slideshow = props => (
    <div className="Slideshow">
        <div className="window">
            <div className="content">
                {props.images[props.activeImage].fileType === 'video/mp4' ? (
                    <video
                        autoPlay
                        playsInline
                        muted
                        loop
                        src={
                            cloud_endpoint + '/Gallery/' + props.images[props.activeImage].src
                        }
                    />
                ) : (
                    <img
                        src={
                            cloud_endpoint + '/Gallery/' + props.images[props.activeImage].src + '?ts=' + new Date().getTime()
                        }
                        alt="Current"
                    />
                )}

                <div className="infos">
                    <div className="text">
                        <h2>{props.images[props.activeImage].sign}</h2>
                        {/* <p>Date: {dateFormat(props.images[props.activeImage].date, 'dd/mm/yyyy HH:MM:ss')}</p> */}
                        <p>
                            {props.images[props.activeImage].sign} {props.images[props.activeImage].address}{' '}
                            {props.images[props.activeImage].zip_code} {props.images[props.activeImage].city}
                        </p>
                    </div>

                    <div className="map">
                        <SimpleMarkerOnMap
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAgStQUqb1f6TTgpgt5Th_BAgmvt2FTv-A&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `auto` }} />}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            position={{
                                lat: props.images[props.activeImage].lat,
                                lng: props.images[props.activeImage].lng
                            }}
                            options={{
                                disableDefaultUI: true,
                                gestureHandling: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="controls">
            {props.activeImage === 0 || (
                <div className="prev" onClick={props.prev}>
                    <i className="fa fa-angle-left" />
                </div>
            )}
            {props.activeImage === props.images.length - 1 || (
                <div className="next" onClick={props.next}>
                    <i className="fa fa-angle-right" />
                </div>
            )}
            <div className="close" onClick={props.closeSlideshow}>
                <div className="fa fa-times" />
            </div>
        </div>
    </div>
);

class Gallery extends React.Component {
    constructor() {
        super();

        this.state = {
            campaign: null,
            filters: [],
            suggestions: [],
            activeImage: null,
            downloading: false
        };

        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleDownloadZip = this.handleDownloadZip.bind(this);

        this.video = null;

        this.password = localStorage.getItem('gallery_data')
            ? JSON.parse(localStorage.getItem('gallery_data')).password
            : '';
    }

    handleKeyPress(e) {
        if (this.state.activeImage !== null) {
            if (e.keyCode === 37) {
                this.handlePrev();
            }

            if (e.keyCode === 39) {
                this.handleNext();
            }

            if (e.keyCode === 27) {
                this.setState({ activeImage: null });
            }
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyPress);
        if (!localStorage.getItem('gallery_data')) {
            return this.props.history.push('/login');
        }

        const password = JSON.parse(localStorage.getItem('gallery_data')).password;

        fetch(`${endpoint}/campaignsGallery`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password })
        })
            .then(res => {
                if (res.status !== 200) {
                    return this.props.history.push('/login');
                }

                return res.json();
            })
            .then(data => {
                this.setState({ campaign: data });
                const suggestions = [];
                data.images.forEach(i => {
                    const entries = [
                        {
                            type: 'sign',
                            name: i.sign + ' (Enseigne)',
                            id: i.sign
                        },
                        {
                            type: 'shop',
                            name: i.name + ' (Shop)',
                            id: i.name
                        },
                        {
                            type: 'zip_code',
                            name: i.zip_code + ' (Zip)',
                            id: i.zip_code
                        },
                        {
                            type: 'city',
                            name: i.city + ' (Ville)',
                            id: i.city
                        },
                        {
                            type: 'address',
                            name: `${i.address} ${i.zip_code} ${i.city} / ${i.sign} (Adresse)`,
                            id: i.address
                        }
                    ];

                    entries.forEach(e => {
                        if (!suggestions.find(s => s.type === e.type && s.id === e.id)) {
                            if (e.id) {
                                suggestions.push(e);
                            }
                        }
                    });
                });

                this.setState({ suggestions: suggestions });
            });
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleNext() {
        if (this.state.activeImage !== null && this.state.activeImage < this.state.campaign.images.length - 1) {
            this.setState({ activeImage: this.state.activeImage + 1 });
        }
    }

    handlePrev() {
        if (this.state.activeImage !== null && this.state.activeImage > 0) {
            this.setState({ activeImage: this.state.activeImage - 1 });
        }
    }

    handleDelete(i) {
        this.setState({
            filters: this.state.filters.filter((_, idx) => i !== idx)
        });
    }

    handleAddition(tag) {
        this.setState({ filters: [...this.state.filters, tag] });
    }

    async handleDownloadZip() {
        this.setState({ downloading: true });

        const zip = new JSZip();

        for (const image of this.state.campaign.images) {
            const res = await fetch(endpoint + '/campaignsGallery/proxy/' + image.src);
            if (res.status === 404) {
                return;
            }

            zip.file(image.src, await res.blob());
        }

        zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, 'galerie.zip');

            this.setState({ downloading: false });
        });
    }

    render() {
        if (!this.state.campaign) {
            return <div>Loading...</div>;
        }

        let images = null;

        let imageElts = this.state.campaign.images;

        this.state.filters.forEach(sf => {
            imageElts = imageElts.filter(f => {
                if (sf.type === 'sign') {
                    return f.sign === sf.id;
                } else if (sf.type === 'zip_code') {
                    return f.zip_code === sf.id;
                } else if (sf.type === 'shop') {
                    return f.shop === sf.id;
                } else if (sf.type === 'city') {
                    return f.city === sf.id;
                } else if (sf.type === 'address') {
                    return f.address === sf.id;
                }

                return false;
            });
        });

        images = imageElts.map((i, index) => (
            <div className="image" key={i._id} onClick={() => this.setState({ activeImage: index })}>
                {i.fileType === 'video/mp4' ? (
                    <video autoPlay playsInline muted loop>
                        <source src={cloud_endpoint + '/Gallery/' + i.src} />
                    </video>
                ) : (
                    <img src={cloud_endpoint + '/Gallery/' + i.src + '?ts=' + new Date().getTime()} alt={i.src} />
                )}

                <div className="overlay">
                    {/* <p>Date: {dateFormat(i.date, 'dd/mm/yyyy HH:MM:ss', true)}</p> */}
                    <p>
                        {i.sign} {i.address} {i.zip_code} {i.city}
                    </p>
                </div>
            </div>
        ));

        return (
            <div className="Gallery">
                <header>
                    <div className="infos">
                        {!this.state.campaign.campaignAsset || this.state.campaign.campaignAsset.slice(-3) === 'mp4' ? (
                            <video height="160" autoPlay loop playsInline muted>
                                <source src={`${endpoint}/static/campaignAsset/${this.state.campaign.campaignAsset}`} />
                            </video>
                        ) : (
                            <img
                                src={`${endpoint}/static/campaignAsset/${this.state.campaign.campaignAsset}`}
                                alt="Preview"
                                height="160"
                            />
                        )}
                        <div className="text">
                            <p>
                                Bienvenue sur la galerie de la campagne <strong>{this.state.campaign.name}</strong>
                            </p>
                            <p>
                                Agence : <strong>{this.state.campaign.agency}</strong>
                            </p>
                            <p>
                                Date :{' '}
                                <strong>
                                    du {dateFormat(this.state.campaign.startDate, 'dd/mm')} au{' '}
                                    {dateFormat(this.state.campaign.endDate, 'dd/mm')}
                                </strong>
                            </p>
                            <p>
                                Particularités : <strong>{this.state.campaign.info}</strong>
                            </p>
                            <p>
                                Télécharger au format ZIP:{' '}
                                <button disabled={this.state.downloading} onClick={this.handleDownloadZip}>
                                    {this.state.downloading ? "Génération de l'archive" : 'Télécharger'}
                                </button>
                            </p>
                        </div>
                    </div>
                    <img src="/assets/image.png" alt="Logo" className="logo" />
                    <div style={{ textAlign: 'right' }}>
                        <button
                            onClick={() => {
                                localStorage.removeItem('gallery_data');
                                this.props.history.push('/login');
                            }}
                        >
                            D&eacute;connexion
                        </button>
                    </div>
                </header>
                <div className="main">
                    <hr />
                    <span className="filterTag">Filtres</span>
                    <div className="filters">
                        <ReactTags
                            suggestions={this.state.suggestions}
                            tags={this.state.filters}
                            handleDelete={this.handleDelete.bind(this)}
                            handleAddition={this.handleAddition.bind(this)}
                        />
                    </div>
                    <div className="group">{images}</div>
                </div>
                {this.state.activeImage === null || (
                    <Slideshow
                        activeImage={this.state.activeImage}
                        images={imageElts}
                        closeSlideshow={() => this.setState({ activeImage: null })}
                        next={this.handleNext}
                        prev={this.handlePrev}
                        password={this.password}
                    />
                )}
            </div>
        );
    }
}

export default Gallery;
