import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './Components/Pages/Login';
import Gallery from './Components/Pages/Gallery';

import './Components/Assets/main.css';

export const endpoint = 'https://api.doohyoulike.com';
export const cloud_endpoint = 'https://storage.googleapis.com/doohyoulike-files';

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/" component={Gallery} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
